import { postData, getData} from './api';

// Fetch strategies by user ID
export const fetchStrategies = async (userID) => {
    const endpoint = `/strategies?userID=${userID}`;
    const { data, error } = await getData(endpoint);
    if (error) {
      throw new Error(error);
    }
  return  data ;
};

// Create a new strategy
export const createStrategy = async (strategyData) => {
  const endpoint = '/strategies';
  const { data, error } = await postData(endpoint, strategyData);
  if (error) {
    throw new Error(error);
  }
  return data;
};

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { createStrategy } from '../../../api/strategy';
import { getUserIdFromToken, getToken } from '../../../services/AuthService';

const AddStrategyModal = ({ show, handleClose, onStrategyAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    rule1: '',
    rule2: '',
    rule3: '',
    equity: '',
    winRate: '',
    profitFactor: '',
    averageWin: '',
    averageLoss: '',
    numberOfTrades: '',
    positiveDays: '',
    numberOfDays: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    const userID = getUserIdFromToken(token);
    const newStrategyData = { ...formData, userID };

    try {
      const data = await createStrategy(newStrategyData);
      onStrategyAdded(data); // Notify parent component about the new strategy
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Error creating strategy:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Strategy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {Object.keys(formData).map((key) => (
            <Form.Group key={key} className="mb-3">
              <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
              <Form.Control
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
              />
            </Form.Group>
          ))}
          <Button variant="primary" type="submit">
            Add Strategy
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddStrategyModal;

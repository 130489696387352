import { getData } from './api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchStates = async (from, to, accountID) => {
  return await getData(`/states/${from}/${to}/${accountID}`);
};

export const fetchStatesThunk = createAsyncThunk(
  'states/fetchStates',
  async ({ from, to, accountID }, { rejectWithValue }) => {
    try {
      const results = fetchStates(from, to, accountID);
      return results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
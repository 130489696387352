import { createSlice } from '@reduxjs/toolkit';
import { fetchAccountsThunk } from '../../api/account';
import { fetchStatesThunk } from '../../api/stats';
import { formatData } from '../../utils/formatter';

const statsSlice = createSlice({
  name: 'stats',
  initialState: {
    accounts: [],
    accountID: null,
    from: '',
    to: '',
    data: {},
    loading: false,
    error: null,
  },
  reducers: {
    setAccountID: (state, action) => {
      state.accountID = action.payload;
    },
    setDateRange: (state, action) => {
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountsThunk.fulfilled, (state, action) => {
        state.accounts = action.payload;
        // state.accountID = action.payload[0]; 
        state.loading = false;
      })
      .addCase(fetchAccountsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchStatesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStatesThunk.fulfilled, (state, action) => {
        state.data = formatData(action.payload.data);
        state.loading = false;
      })
      .addCase(fetchStatesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setAccountID, setDateRange } = statsSlice.actions;
export default statsSlice.reducer;

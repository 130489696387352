import React, { useState } from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import '../../../../css/daterange-picker.css';

const DateRangePicker = ({ onDateRangeChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date && endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApply = () => {
    if (startDate && endDate) {
      onDateRangeChange({ from: dayjs(startDate).format('YYYY-MM-DD'), to: dayjs(endDate).format('YYYY-MM-DD') });
      setIsCalendarOpen(false);
    }
  };

  const handlePresetClick = (preset) => {
    const today = dayjs();
    let startDatePreset;
    let endDatePreset = today.toDate();

    switch (preset) {
      case 'This Week':
        startDatePreset = today.startOf('week').toDate();
        break;
      case 'This Month':
        startDatePreset = today.startOf('month').toDate();
        break;
      case 'YTD':
        startDatePreset = today.startOf('year').toDate();
        break;
      default:
        startDatePreset = today.toDate();
        break;
    }

    setStartDate(startDatePreset);
    setEndDate(endDatePreset);
    onDateRangeChange({ from: dayjs(startDatePreset).format('YYYY-MM-DD'), to: dayjs(endDatePreset).format('YYYY-MM-DD') });
    setIsCalendarOpen(false);
  };

  return (
    <div className="date-range-picker">
      <input
        type="text"
        value={startDate && endDate ? `${dayjs(startDate).format('YYYY-MM-DD')} - ${dayjs(endDate).format('YYYY-MM-DD')}` : 'Select Date Range'}
        readOnly
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        className="form-control"
      />

      {isCalendarOpen && (
        <div className="calendar-dropdown">
          <div className="calendar-section">
            <label>Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="calendar-section">
            <label>End Date</label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
          <div className="calendar-actions">
            <Button variant="primary" onClick={handleApply} disabled={!startDate || !endDate}>
              Apply
            </Button>
            <DropdownButton id="dropdown-basic-button" title="Presets" variant="secondary">
              <Dropdown.Item onClick={() => handlePresetClick('This Week')}>This Week</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePresetClick('This Month')}>This Month</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePresetClick('YTD')}>YTD</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;

import {jwtDecode} from 'jwt-decode';
export const isAuthenticated = () => {
    const token = localStorage.getItem('JWT');
    if (!token) return false;
    
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('JWT'); // Token has expired, remove it
        return false;
      }
      return true;
    } catch (error) {
      console.error('Invalid token', error);
      return false;
    }
};

import React from "react";
import { Doughnut } from "react-chartjs-2";

const TotalProfits = ({ totalPnl }) => {
  const data = {
    labels: ["Profits"],
    datasets: [
      {
        data: totalPnl !== null ? [totalPnl] : [0],
        backgroundColor: ["#36A2EB"],
        hoverBackgroundColor: ["#36A2EB"]
      }
    ]
  };
  const options = {
    cutout: "70%",
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: false },
      hover: { mode: null }
    }
  };

  return;
};

export default TotalProfits;

import React, { Fragment, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Container,
} from "react-bootstrap";
/// imge
import { Link } from "react-router-dom";
const Settings = () => {
    return(
        <>
        <div className="container-fluid">
          <Row>
            <Col lg="3">
              <Card>
                <h1>col</h1>
              </Card>
            </Col>
          <Col lg="9">
            </Col>
          </Row>
          
        </div>
        </>
    )
}

export default Settings;
export const formatData = (data) => {
    return {
      ...data,
      totalPnl: parseFloat(data.totalPnl).toFixed(2),
      avgWinningPnl: parseFloat(data.avgWinningPnl).toFixed(2),
      avgLosingPnl: parseFloat(data.avgLosingPnl).toFixed(2),
      winRate: parseFloat(data.winRate).toFixed(2),
      profitFactor: parseFloat(data.profitFactor).toFixed(2),
      avgWinLoss: parseFloat(data.avgWinLoss).toFixed(2),
    };
  };
import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

const AddTradesLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="container-fluid mt-4">
      <h1>Add Trades</h1>

      {/* Button Group - Always Visible */}
      <ButtonGroup className="mb-4">
        <Button
          variant={location.pathname === '/add-trade/csv' ? 'primary' : 'outline-primary'}
          onClick={() => navigateTo('/add-trade/csv')}
        >
          File Upload
        </Button>
        <Button
          variant={location.pathname === '/add-trade/broker' ? 'primary' : 'outline-primary'}
          onClick={() => navigateTo('/add-trade/broker')}
        >
          Broker Sync
        </Button>
        <Button
          variant={location.pathname === '/add-trade/manual' ? 'primary' : 'outline-primary'}
          onClick={() => navigateTo('/add-trade/manual')}
        >
          Manual
        </Button>
      </ButtonGroup>

      <Outlet />
    </div>
  );
};

export default AddTradesLayout;

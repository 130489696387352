import React, { useState } from "react";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import PageTitle from "../../layouts/PageTitle";
import {Row, Card, Table, Badge, Button, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { fetchAccounts } from '../../../api/account'; 
import { getUserIdFromToken, getToken } from '../../../services/AuthService';
import CreateAccountModal from './CreateAccountModal';

const Account = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const token = getToken(); 
  const userID = getUserIdFromToken(token);
  const { data: accounts = [], isLoading, error } = useQuery({
    queryKey: ['accounts', userID],
    queryFn: () => fetchAccounts(userID),
    enabled: !!userID, // Only run query if userID is available
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleAccountCreated = (newAccount) => {
    // Optionally update the cache to reflect the new account
    queryClient.invalidateQueries(['accounts', userID]);
  };
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="container-fluid">
        <Card>
          <Card.Header>
            <Card.Title>Accounts</Card.Title>
            <Button
              variant="primary"
              onClick={handleShowModal}
              style={{ float: 'right' }}
            >
              + Add Account
            </Button>
          </Card.Header>
          <Card.Body>
            <Table responsive striped className="header-border">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Balance</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {accounts.length > 0 ? (
                  accounts.map((account) => (
                    <tr key={account._id}>
                      <td>{account.name}</td>
                      <td>{account.balance.toFixed(2)}</td>
                      <td>{account.status}</td>
                      <td>
                        <Link to={`/account/edit/${account._id}`}>Edit</Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="15">No accounts available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <CreateAccountModal
          show={showModal}
          handleClose={handleCloseModal}
          onAccountCreated={handleAccountCreated}
        />
      </div>
    </>
  );
};

export default Account;

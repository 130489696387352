import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import {jwtDecode} from 'jwt-decode';

const apiUrl = process.env.REACT_APP_API_DOMAIN;
export function signUp(email, password,username) {
    //axios call
    const postData = {
        email,
        password,
        username
    };
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios.post(
        `${apiUrl}/users`,
        postData,
        config
    ) .then(response => {
        console.log('Sign-up request successful:', response.data);
        return response; 
    })
    .catch(error => {
        console.log('Error sending sign-up request:', error);
        throw error;
    });;
}

export function login(email, password) {
    const postData = {
        email,
        password,
        // returnSecureToken: true,
    };
    return axios.post(
        `${apiUrl}/users/login`,
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.code) {
        case 'INVALID_IDENTIFIERS':            
            swal("Oops", "Wrong email or password.", "error", { button: "Try Again!",});
            break;
        case 'USERNAME_REQUIRED':
           swal("Oops", "Username is required", "error",{ button: "Try Again!",});
           break;
        case 'EMAIL_REQUIRED':
            swal("Oops", "Email is required", "error",{ button: "Try Again!",});
            break;
        case 'PASSWORD_REQUIRED':
            return 'Password is required';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    localStorage.setItem('JWT', tokenDetails);
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        // dispatch(Logout(history));
        // dispatch(Logout(navigate));
    }, timer);
}

 export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('JWT');
    let tokenDetails = tokenDetailsString ? jwtDecode(tokenDetailsString) : '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }
    let expireDate = new Date(tokenDetails.exp * 1000);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}

export const getUserIdFromToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded._id;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };
  
  export const getToken = () => localStorage.getItem("JWT");
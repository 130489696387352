import React from 'react';
import { Table } from 'react-bootstrap';
import '../../../scss/trade/trade-list.scss';

const StrategyList = ({ strategies }) => {
  return (
    <div className='mt-3'>
      <Table bordered hover responsive className="styled-table text-center bg-white">
        <thead>
          <tr>
            <th>Strategy Name</th>
            <th>Trades</th>
            <th>Net PnL</th>
            <th>Win Rate</th>
          </tr>
        </thead>
        <tbody>
          {strategies.map((strategy) => (
            <tr key={strategy._id} className="hover-effect">
              <td>{strategy.numberOfTrades}</td>
              <td>{strategy.name}</td>
              <td className={strategy.pnl > 0 ? 'green-pnl' : strategy.pnl < 0 ? 'red-pnl' : ''}>{strategy.pnl || 0}</td>
              <td>{strategy.winRate}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StrategyList;

import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import moment from 'moment';

const DailyPnlChart = ({ trades }) => {
  // Transform trades into a daily summary of PnL
  const data = useMemo(() => {
    const dailyPnlMap = trades.reduce((acc, trade) => {
      const date = moment(trade.closingTime).format('YYYY-MM-DD');
      acc[date] = (acc[date] || 0) + trade.pnl;
      return acc;
    }, {});

    return Object.keys(dailyPnlMap).map((date) => ({
      date,
      pnl: dailyPnlMap[date],
    }));
  }, [trades]);

  const getBarColor = (pnl) => {
    return pnl >= 0 ? '#82ca9d' : '#ff4d4f';
  };

  return (
    <Card className="mb-4 shadow-sm">
      <Card.Header className=" text-white">
        <h5 className="mb-0">Daily Net PnL</h5>
      </Card.Header>
      <Card.Body>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="date" tickFormatter={(date) => moment(date).format('MMM DD')} />
            <YAxis />
            <Tooltip formatter={(value) => `$${value.toFixed(2)}`} />
            <Legend />
            <Bar dataKey="pnl" name="Net PnL">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getBarColor(entry.pnl)} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};

export default DailyPnlChart;

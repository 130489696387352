import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountsThunk } from '../../../api/account'; 
import { fetchStatesThunk } from '../../../api/stats'; 
import { fetchTradesThunk } from '../../../api/trade';
import { getToken, getUserIdFromToken } from '../../../services/AuthService';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { setAccountID } from '../../../store/slices/statsSlice';
import DateRangePicker from '../../components/Dashboard/elements/DateRangePicker';

const SearchComponent = () => {
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState('');
  const [dateRange, setDateRange] = useState({
    from: '',
    to: '',
  });

  const {accounts} = useSelector((state) => state.states); 
  const userID = getUserIdFromToken(getToken());

  useEffect(() => {
    if (userID) {
      dispatch(fetchAccountsThunk(userID));
    }
  }, [dispatch, userID]);

  useEffect(() => {
    if (accounts && accounts.length > 0 && !selectedAccount) {
      setSelectedAccount(accounts[0]._id);
      dispatch(setAccountID(accounts[0]._id));
    }
  }, [accounts]);

  const handleSearch = (dateRange) => {
    if (selectedAccount && dateRange.from && dateRange.to) {
      dispatch(fetchStatesThunk({
        from: dateRange.from,
        to: dateRange.to,
        accountID: selectedAccount,
      }));
      dispatch(fetchTradesThunk({
        from: dateRange.from,
        to: dateRange.to,
        accountID: selectedAccount,
      }));
    }
  };

  const handleAccountChange = (e) => {
    const selectedAccountID = e.target.value;
    setSelectedAccount(selectedAccountID);
    dispatch(setAccountID(selectedAccountID));
  };

  return (
    <Form className="search-component">
      <Row>
        <Col md={6}>
          <Form.Group controlId="accountSelect">
            <Form.Control
              as="select"
              value={selectedAccount}
              onChange={handleAccountChange}
            >
              {accounts.map((account) => (
                <option key={account._id} value={account._id}>
                  {account.name ? account.name : account._id}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={6}>
          <DateRangePicker onDateRangeChange={handleSearch} />
        </Col>
      </Row>
    </Form>
  );
};

export default SearchComponent;

// src/store/slices/tradesSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchTradesThunk } from '../../api/trade';

const tradesSlice = createSlice({
  name: 'trades',
  initialState: {
    trades: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradesThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTradesThunk.fulfilled, (state, action) => {
        state.trades = action.payload;
        state.loading = false;
      })
      .addCase(fetchTradesThunk.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { setAccountID, setDateRange } = tradesSlice.actions;
export default tradesSlice.reducer;

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { updateProfile } from '../../../api/profile'; 
import { getUserIdFromToken, getToken } from '../../../services/AuthService';

const UpdateProfileModal = ({ show, handleClose, onProfileUpdated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken(); 
    const userID = getUserIdFromToken(token);

    const { data, error } = await updateProfile(userID, formData);

    if (error) {
      console.error('Error updating profile:', error);
      // Optionally show an error message to the user
    } else {
      onProfileUpdated(data); // Notify the parent component about the profile update
      handleClose(); // Close the modal
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Update Profile
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProfileModal;

import { SVGICON } from "../../constant/theme";

export const MenuList = [
    //Content
    {
        title: 'Add Trade',
        to: '/add-trade/csv',
        iconStyle: SVGICON.PlusSymbal,
        className: 'button-nav',
        // classsChange: 'menu-title'
    },
    //Dashboard
    {
        title: 'Dashboard',
        to: '/dashboard',		
        iconStyle: SVGICON.Home,
    },
    {   
        title:'Trade Log',
        iconStyle: SVGICON.TaskIcon,
        to: '/trades',
    },
    {   
        title:'Strategy',
        iconStyle: SVGICON.ProjectIcon,
        to: '/strategies',
    },   
    {   
        title:'Accounts',
        iconStyle: SVGICON.EditPrimary,
        to: '/accounts',
    },   
]
import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchProfile } from '../../../api/profile'; 
import { getUserIdFromToken, getToken } from '../../../services/AuthService';
import UpdateProfileModal from './UpdateProfileModal';

const Profile = () => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const token = getToken(); 
  const userID = getUserIdFromToken(token);

  const { data: profile, isLoading, error } = useQuery({
    queryKey: ['profile', userID],
    queryFn: () => fetchProfile(userID),
    enabled: !!userID, // Only run query if userID is available
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleProfileUpdated = (updatedProfile) => {
    // Optionally update the cache to reflect the updated profile
    queryClient.invalidateQueries(['profile', userID]);
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
    <div className="container-fluid">
      <Card>
        <Card.Header>
          <Card.Title>Profile</Card.Title>
          <Button
            variant="primary"
            onClick={handleShowModal}
            style={{ float: 'right' }}
          >
            Edit Profile
          </Button>
        </Card.Header>
        <Card.Body>
          <p>Email: {profile.email}</p>
        </Card.Body>
      </Card>
      <UpdateProfileModal
        show={showModal}
        handleClose={handleCloseModal}
        onProfileUpdated={handleProfileUpdated}
      />
    </div>
    </>
  );
};

export default Profile;



const BrokerSync = () => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-100 bg-white">
            <div className="text-center p-4 bg-white ">
                <h2 className="mb-3">Broker Sync</h2>
                <p className="lead text-muted">Coming soon...</p>
            </div>
        </div>
    );
};

export default BrokerSync;

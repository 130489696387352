import { getData, putData } from './api'; // Adjust the import path as needed

export const fetchProfile = async (userID) => {
  const endpoint = `/users/${userID}`;
  const { data, error } = await getData(endpoint);
  if (error) throw new Error(error.message);
  return data;
};

export const updateProfile = async (userID, profileData) => {
  const endpoint = `/users/${userID}`; // Use the correct endpoint
  const { data, error } = await putData(endpoint, profileData); // Use putData for updating
  if (error) throw new Error(error.message);
  return data;
};

import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { Card } from 'react-bootstrap'; 
import '../../../../css/event-calendar.css'; 

const TradeCalendar = ({ trades }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const formattedEvents = [];
    if (!trades || trades.length === 0) {
      setEvents([]);
      return;
    }
    trades.forEach((trade) => {
      const tradeDate = moment(trade.closingTime).format('YYYY-MM-DD');
      const existingEvent = formattedEvents.find((event) => event.date === tradeDate);

      if (existingEvent) {
        existingEvent.extendedProps.tradeCount += 1;
        existingEvent.extendedProps.totalPnl += trade.pnl;
        existingEvent.title = `Trades: ${existingEvent.extendedProps.tradeCount}, PnL: ${existingEvent.extendedProps.totalPnl.toFixed(2)}`;
      } else {
        formattedEvents.push({
          title: `Trades: 1, PnL: ${trade.pnl ? trade.pnl.toFixed(2) : 0}`,
          date: tradeDate,
          extendedProps: {
            tradeCount: 1,
            totalPnl: trade.pnl ? trade.pnl : 0,
          },
        });
      }
    });

    setEvents(formattedEvents);
  }, [trades]);
  
  const eventClassNames = ({ event }) => {
    if (event.extendedProps.totalPnl == 0) return '';
    return event.extendedProps.totalPnl > 0 ? 'positive-pnl' : 'negative-pnl';
  };

  return (
    <div >
      <Card className="mb-4 shadow-sm">
        <Card.Header className="">
          <h4 className="mb-0">Monthly Trade Calendar</h4>
        </Card.Header>
        <Card.Body>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            headerToolbar={{
              left: 'prev',
              center: 'title',
              right: 'next today'
            }}
            eventContent={(eventInfo) => (
              <div className="custom-event-content">
                <b>{eventInfo.event.title}</b>
              </div>
            )}
            eventClassNames={eventClassNames}
            showNonCurrentDates={false}
            contentHeight={600}
            aspectRatio={1} 
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default TradeCalendar;

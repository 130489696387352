import { postData, getData, putData} from './api';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const createTrade = async (tradeData) => {
  const endpoint = '/trades/createTrade';
  return await postData(endpoint, tradeData);
};

export const createTrades = async (tradeData) => {
  const endpoint = '/trades/createTrades';
  return await postData(endpoint, tradeData);
};

export const fetchTradesThunk = createAsyncThunk(
  'trades/fetchTrades',
  async ({ accountID, from, to }, { rejectWithValue }) => {
    try {
      const response = fetchTrades(accountID, from, to)
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message || error.message);
    }
  }
);

// Function to fetch trades
export const fetchTrades = async (accountID, from, to) => {
    const endpoint = `/trades/account/${accountID}`;
    const { data, error } = await getData(endpoint, {from, to});
    if (error) {
        throw new Error(error);
    }
    return data; 
};

export const fetchTradeByID = async (tradeID) => {
    const endpoint = `/trades/${tradeID}`;
    const { data, error } = await getData(endpoint);
    if (error) {
        throw new Error(error);
    }
    return data; 
};

export const modifyTrade = async (tradeID, data) => {
    const endpoint = `/trades/${tradeID}`;
    return await putData(endpoint, data);
};


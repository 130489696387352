import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchTradeByID } from '../../../api/trade';
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';

const TradeDetail = () => {
  const { tradeID } = useParams();
  const [trade, setTrade] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getTrade = async () => {
      try {
        const tradeData = await fetchTradeByID(tradeID);
        setTrade(tradeData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getTrade();
  }, [tradeID]);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-4">
        <Alert variant="danger">Error: {error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      {trade ? (
        <Card className="shadow-lg">
          <Card.Header as="h2" className="bg-primary text-white text-center">
            Trade Details
          </Card.Header>
          <Card.Body>
            <Row className="mb-4">
              <Col md={6}>
                <Card.Title className="mb-4">Trade Information</Card.Title>
                <Card.Text><strong>Symbol:</strong> {trade.symbol}</Card.Text>
                <Card.Text><strong>Side:</strong> {trade.side}</Card.Text>
                <Card.Text><strong>Volume:</strong> {trade.volume}</Card.Text>
                <Card.Text><strong>Pips:</strong> {trade.pips}</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Title className="mb-4">Financials</Card.Title>
                <Card.Text><strong>Entry Price:</strong> {trade.entryPrice}</Card.Text>
                <Card.Text><strong>Closing Price:</strong> {trade.closingPrice}</Card.Text>
                <Card.Text><strong>PnL:</strong> {trade.pnl}</Card.Text>
                <Card.Text><strong>Net Profit:</strong> {trade.netProfit}</Card.Text>
                <Card.Text><strong>Gross Profit:</strong> {trade.grossProfit}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Card.Title className="mb-4">Timing</Card.Title>
                <Card.Text><strong>Entry Time:</strong> {new Date(trade.entryTime).toLocaleString()}</Card.Text>
                <Card.Text><strong>Closing Time:</strong> {new Date(trade.closingTime).toLocaleString()}</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Title className="mb-4">Additional Info</Card.Title>
                <Card.Text><strong>Commission:</strong> {trade.commission}</Card.Text>
                <Card.Text><strong>Swap:</strong> {trade.swap}</Card.Text>
                <Card.Text><strong>Balance:</strong> {trade.balance}</Card.Text>
                <Card.Text><strong>Strategy:</strong> {trade.strategyID}</Card.Text>
                <Card.Text><strong>Result:</strong> {trade.result}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <Alert variant="warning">No trade found.</Alert>
      )}
    </Container>
  );
};

export default TradeDetail;

import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { load } from 'cheerio';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountID } from '../../../store/slices/statsSlice';
import { Form } from 'react-bootstrap';
import { createTrades } from '../../../api/trade';
import { getToken, getUserIdFromToken } from '../../../services/AuthService';


function ImportTrades() {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [parsedData, setParsedData] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedBroker, setSelectedBroker] = useState('ctrader'); // Default to ctrader
    const userID = getUserIdFromToken(getToken());
    const { accounts } = useSelector((state) => state.states);
    const accountID = useSelector((state) => state.states.accountID);

    useEffect(() => {
        if (!accountID && accounts.length > 0) {
            setSelectedAccount(accounts[0]._id);
            dispatch(setAccountID(accounts[0]._id));
        }
    }, [accountID, accounts, dispatch]);

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
        
        if (!uploadedFile) {
            setMessage('Please select a file first.');
            return;
        }

        const fileType = uploadedFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();

        reader.onload = (e) => {
            const fileContent = e.target.result;
            
            if (selectedBroker === 'ctrader') {
                // Logic for cTrader
                if (fileType === 'csv') {
                    parseCSV(fileContent);
                } else if (fileType === 'htm' || fileType === 'html') {
                    parseHTM(fileContent);
                } else {
                    setMessage('Unsupported file type for cTrader.');
                }
            } else {
                setMessage('Selected broker is not supported yet.');
            }
        };

        reader.readAsText(uploadedFile);
    };

    const parseCSV = (data) => {
        Papa.parse(data, {
            header: true,
            complete: (result) => {
                setParsedData(result.data.map((row) => ({
                    ...row,
                    accountID: selectedAccount,
                })));
                setMessage('CSV parsed successfully.');
            },
            error: (error) => {
                console.error(error);
                setMessage('Error parsing CSV.');
            }
        });
    };

    const parseHTM = (data) => {
        const $ = load(data);
        const rows = [];
        
        const table = $('table.dataTable').first();
        const allRows = table.find('tr');
        
        allRows.each((i, row) => {
            if (i <= 1 || i === allRows.length - 1) return;

            const cells = $(row).find('td');

            const closingTimeFormatted = moment($(cells[3]).text().trim(), "DD/MM/YYYY HH:mm:ss.SSS").format("YYYY-MM-DDTHH:mm");
            if (cells.length) {
                rows.push({
                    accountID: selectedAccount,
                    userID: userID,
                    symbol: $(cells[1]).text().trim(),
                    side: $(cells[2]).text().trim(),
                    closingTime: closingTimeFormatted,
                    entryPrice: parseFloat($(cells[4]).text().replace(/\u00A0/g, '').replace(',', '.')),
                    closingPrice: parseFloat($(cells[5]).text().replace(/\u00A0/g, '').replace(',', '.')),
                    volume: parseFloat($(cells[6]).text().replace(/\u00A0/g, '').replace(' Lots', '').replace(',', '.')),
                    pnl: parseFloat($(cells[7]).text().replace(/\u00A0/g, '').replace(',', '.')),
                });
            }
        });

        setParsedData(rows);
        setMessage('HTML parsed successfully.');
    };

    const handleUpload = async () => {
        if (parsedData.length === 0) {
            Swal.fire('Error', 'No data to upload.', 'error');
            return;
        }
        try {
            const { data, error } = await createTrades(parsedData);
            if (error) {
                console.log('Error uploading data:', error);
                Swal.fire('Error', 'Error uploading data.', 'error');
            } else {
                Swal.fire('Success', 'Trades imported successfully!', 'success');
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Error', 'Error uploading data.', 'error');
        }
    };

    const handleAccountChange = (e) => {
        const selectedAccountID = e.target.value;
        setSelectedAccount(selectedAccountID);
        dispatch(setAccountID(selectedAccountID));
    };

    const handleBrokerChange = (e) => {
        setSelectedBroker(e.target.value);
    };

    return (
        <div className="mt-4">
            <Form.Group controlId="accountSelect">
                <Form.Label>Select Account</Form.Label>
                <Form.Control
                    as="select"
                    value={selectedAccount}
                    onChange={handleAccountChange}
                >
                    <option value="">-- Select an Account --</option>
                    {accounts.map((account) => (
                        <option key={account._id} value={account._id}>
                            {account.name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="brokerSelect" className="mt-3">
                <Form.Label>Select Broker</Form.Label>
                <Form.Control
                    as="select"
                    value={selectedBroker}
                    onChange={handleBrokerChange}
                >
                    <option value="ctrader">cTrader</option>
                </Form.Control>
            </Form.Group>
            {selectedAccount && (
                <>
                    <input
                        type="file"
                        className="form-control mb-3 mt-3"
                        onChange={handleFileChange}
                    />
                    {parsedData.length > 0 && (
                        <div className="mt-4">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Symbol</th>
                                        <th>Direction</th>
                                        <th>Close Time</th>
                                        <th>Entry Price</th>
                                        <th>Close Price</th>
                                        <th>Quantity</th>
                                        <th>Net USD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parsedData.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.symbol}</td>
                                            <td ><p className='outline-primary'>{row.side}</p></td>
                                            <td>{row.closingTime}</td>
                                            <td>{row.entryPrice}</td>
                                            <td>{row.closingPrice}</td>
                                            <td>{row.volume}</td>
                                            <td>{row.pnl}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {parsedData.length > 0 && (
                        <button
                            className="btn btn-primary"
                            onClick={handleUpload}
                        >
                            Upload Trades
                        </button>
                    )}
                </>
            )}
        </div>
    );
}

export default ImportTrades;

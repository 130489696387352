import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { fetchTrades, fetchTradesThunk } from "../../../api/trade";

// Import Components
import { IMAGES, SVGICON } from "../../constant/theme";
import DonutChart from "../../pages/WidgetBasic/DonutChart";
import GradientArea from "../charts/Chartjs/gradinetArea";
import TradeCalendar from "../AppsMenu/Calendar/TradeCalendar";
import ProjectOverviewTab from "./elements/ProjectOverviewTab";
import ActiveProjects from "./elements/ActiveProjects";
import MyProgressChart from "./elements/MyProgressChart";
import TotalProfits from "./elements/TotalProfits";
import DailyPnlChart from "./DailyPnlChart";

// Dynamically loaded components
const RevenueChart = loadable(() =>
  pMinDelay(import("./elements/RevenueChart"), 1000)
);
const ExpensesBarChart = loadable(() =>
  pMinDelay(import("./elements/ExpensesBarChart"), 1000)
);
const TotalDeposit = loadable(() =>
  pMinDelay(import("./elements/TotalDeposit"), 1000)
);

const DashboardDark = () => {
  const [trades, setTrades] = useState([]);
  
  const dispatch = useDispatch();
  const {avgWinLoss = 0, avgWinningPnl = 0 , avgLosingPnl = 0, profitFactor = 0, totalPnl = 0, winRate = 0, loading, error } = useSelector((state) => state.states.data);
  const {accountID} = useSelector((state) => state.states);
  useEffect(() => {
    if (accountID) {
      const fetchTradesData = async () => {
        try {
          const tradesData = await fetchTrades(accountID);
          setTrades(tradesData);
        } catch (error) {
          console.error("Error fetching trades:", error);
        }
      };

      fetchTradesData();
    }
  }, [accountID]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-3 col-sm-3">
                        <div className="card bg-primary text-white">
                          <div className="card-header border-0 flex-wrap">
                            <div className="revenue-date">
                              <span>Profit Factor</span>
                              <h4 className="text-white">{profitFactor}</h4>
                            </div>
                          </div>
                          <div className="card-body pb-0 custome-tooltip d-flex align-items-center">
                            {/* <RevenueChart /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-3">
                        <div className="card bg-secondary text-white">
                          <div className="card-header border-0">
                            <div className="revenue-date">
                              <span className="text-black">Avg win/ Avg loss</span>
                              <h4 className="text-black">{avgWinLoss}</h4>
                            </div>
                          </div>
                          <div className="card-body pb-0 custome-tooltip d-flex align-items-center">
                            {/* <ExpensesBarChart /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-3">
                        <div className="card same-card">
                          <div className="card-body depostit-card p-0">
                            <div className="depostit-card-media d-flex justify-content-between pb-0">
                              <div>
                                <h6>PNL</h6>
                                <h3>${totalPnl}</h3>
                              </div>
                              <div className="icon-box bg-primary">
                                {SVGICON.Doller}
                              </div>
                            </div>
                            <TotalProfits totalPnl={totalPnl}/>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="students1 d-flex align-items-center justify-content-between flex-wrap">
                              <div>
                                <h4>Winrate</h4>
                                <h4>%{winRate}</h4>
                              </div>
                              <div>
                                {/* <div className="d-inline-block position-relative donut-chart-sale mb-3">
                                  <DonutChart
                                    value={winRate}
                                    backgroundColor="rgba(59, 215, 225,1)"
                                    backgroundColor2="rgba(245, 245, 245, 1)"
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-xl-12 col-sm-12">
                        <div className="card">
                          <div className="card-body depostit-card">
                            <div className="depostit-card-media d-flex justify-content-between style-1">
                              <div>
                                <h6>Tasks Not Finished</h6>
                                <h3>20</h3>
                              </div>
                              <div className="icon-box bg-secondary">
                                {SVGICON.Shiled}
                              </div>
                            </div>
                            <div className="progress-box mt-0">
                              <div className="d-flex justify-content-between">
                                <p className="mb-0">Complete Task</p>
                                <p className="mb-0">20/28</p>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar bg-secondary"
                                  style={{
                                    width: "50%",
                                    height: "5px",
                                    borderRadius: "4px",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="col-xl-4 col-sm-6">
                    <div className="card">
                      <div className="card-body">
                        <MyProgressChart />
                        <span className="right-sign">{SVGICON.RightCheck}</span>
                        <div className="redia-date text-center">
                          <h4>My Progress</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur</p>
                          <Link
                            to={"/crm"}
                            className="btn btn-secondary text-black"
                          >
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-xl-4 col-sm-12 h-100">
                      <DailyPnlChart trades={trades} />
                  </div>
                  <div className="col-xl-8 col-sm-12">
                    <TradeCalendar trades = {trades}/>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    {/* <GradientArea /> */}
                  </div>
                  <div className="col-xl-12 col-xxl-12">
                    {/* <ActiveProjects /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardDark;

import React, { useState, useEffect } from 'react';
import TradeList from './TradeList';
import { useSelector } from "react-redux";


const TradePage = () => {

  const {trades, loading, error } = useSelector((state) => state.trades);
  

  if (loading) return <p>Loading trades...</p>;
  if (error) return <p>Error loading trades: {error.message}</p>;

  return (
    <div className='container-fluid'>
      <h1>History</h1>
      <TradeList trades={trades} />
    </div>
  );
};

export default TradePage;

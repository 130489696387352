import React, { useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../../scss/trade/trade-list.scss'

const TradeList = ({ trades }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedTrades, setSortedTrades] = useState(trades);
  const itemsPerPage = 15;
  const totalPages = Math.ceil(trades.length / itemsPerPage);
  const navigate = useNavigate();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTrades = sortedTrades.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowClick = (tradeID) => {
    navigate(`/trades/${tradeID}`);
  };

  const handleSort = () => {
    const sorted = [...sortedTrades].sort((a, b) => {
      const pnlA = parseFloat(a.pnl) || 0;
      const pnlB = parseFloat(b.pnl) || 0;
      return sortOrder === 'asc' ? pnlA - pnlB : pnlB - pnlA;
    });

    setSortedTrades(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const getPnlClass = (pnl) => {
    if (pnl > 0) return 'green-pnl';
    if (pnl < 0) return 'red-pnl';
    return '';
  };

  return (
    <div className="container-fluid mt-4">
      <Table bordered hover responsive className="text-center bg-white styled-table">
        <thead className="">
          <tr>
            <th>Open Date</th>
            <th>Symbol</th>
            <th>Close Date</th>
            <th>Entry Price</th>
            <th>Exit Price</th>
            <th onClick={handleSort} style={{ cursor: 'pointer' }}>
              Net PnL {sortOrder === 'asc' ? '▲' : '▼'}
            </th>
            <th>Strategy</th>
          </tr>
        </thead>
        <tbody>
          {currentTrades.map((trade) => (
            <tr
              key={trade._id}
              onClick={() => handleRowClick(trade._id)}
              className="hover-effect"
            >
              <td>{new Date(trade.entryTime).toLocaleString()}</td>
              <td>{trade.symbol}</td>
              <td>{new Date(trade.closingTime).toLocaleString()}</td>
              <td>{trade.entryPrice}</td>
              <td>{trade.closingPrice}</td>
              <td className={getPnlClass(trade.pnl)}>{trade.pnl || '0'}</td>
              <td style={{ maxWidth: '120px' }}>{trade.strategyID}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </div>
    </div>
  );
};

export default TradeList;

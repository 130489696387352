import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { createAccount } from '../../../api/account'; 
import { getUserIdFromToken, getToken } from '../../../services/AuthService';
import Swal from 'sweetalert2';

const CreateAccountModal = ({ show, handleClose, onAccountCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
    balance: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken(); 
    const userID = getUserIdFromToken(token);

    // Basic validation
    if (!formData.name || isNaN(parseFloat(formData.balance))) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Input',
        text: 'Please ensure all fields are correctly filled out.',
      });
      return;
    }

    const newAccountData = { ...formData, userID };
    try {
      const { data, error } = await createAccount(newAccountData);

      if (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Error creating account. Please try again`,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Account created successfully!',
        }).then(() => {
          onAccountCreated(data);
          handleClose();
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An unexpected error occurred. Please try again`,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Starting Balance</Form.Label>
            <Form.Control
              type="number"
              name="balance"
              value={formData.balance}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Create Account
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAccountModal;

import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import StrategyList from './StrategyList';
import AddStrategyModal from './AddStrategyModal';
import { fetchStrategies } from '../../../api/strategy';
import { getUserIdFromToken, getToken } from '../../../services/AuthService';

const StrategyPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [strategies, setStrategies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadStrategies = async () => {
      const token = getToken();
      const userID = getUserIdFromToken(token);
      try {
        const response = await fetchStrategies(userID);
        setStrategies(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    loadStrategies();
  }, []);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleStrategyAdded = (newStrategy) => {
    setStrategies((prevStrategies) => [...prevStrategies, newStrategy]);
    handleCloseModal();
  };

  if (loading) return <p>Loading strategies...</p>;
  if (error) return <p>Error loading strategies: {error.message}</p>;

  return (
    <div className='container-fluid'>
      <h1>Strategies</h1>
      <Button onClick={handleOpenModal}>Add Strategy</Button>
      <StrategyList strategies={strategies} />
      <AddStrategyModal
        show={showModal}
        handleClose={handleCloseModal}
        onStrategyAdded={handleStrategyAdded}
      />
    </div>
  );
};

export default StrategyPage;

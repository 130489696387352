import { getData, postData, putData, deleteData } from './api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAccounts = async (userID) => {
  const endpoint = `/accounts/user/${userID}`;
  const { data, error } = await getData(endpoint);
  if (error) {
    throw new Error(error);
  }
  return data; 
};
export const fetchAccountsThunk = createAsyncThunk(
  'stats/fetchAccounts',
  async (userID, { rejectWithValue }) => {
    try {
      const data = await fetchAccounts(userID);
      return data; // Adjust based on the structure of the returned data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createAccount = async (accountData) => {
  return await postData('/accounts', accountData);
};

export const deleteAccount = async (accountID) => {
  return await deleteData(`/accounts/${accountID}`);
};

import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN, 
  timeout: 10000,
});


// Function to handle API requests and catch errors
const handleRequest = async (request) => {
    try {
      const response = await request();
      return { data: response.data, error: null };
    } catch (error) {
      console.error('API call error:', error);
      return { data: null, error: error.response ? error.response.data : 'Network Error' };
    }
};

// Helper function to get token
const getToken = () => localStorage.getItem("JWT");

// Function to get data with formatting
export const getData = async (endpoint, params = {}) => {
  const token = getToken();
  const result = await handleRequest(() =>
    apiClient.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    })
  );
  // Format data to ensure it's an array if applicable
  if (result.error) {
    return result; // Return the error as is
  }
  if (Array.isArray(result.data)) {
    return { data: result.data, error: null };
  } else {
    return { data: result.data, error: null };
  }
};

// Function to post data
export const postData = async (endpoint, data) => {
  const token = getToken();
  return await handleRequest(() =>
    apiClient.post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

// Function to delete data
export const deleteData = async (endpoint) => {
  const token = getToken();
  return await handleRequest(() =>
    apiClient.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

// Function to put data
export const putData = async (endpoint, data) => {
  const token = getToken();
  return await handleRequest(() =>
    apiClient.put(endpoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};
import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Container, Row, Col } from 'react-bootstrap';
import { createTrade } from '../../../api/trade'; 
import { getUserIdFromToken, getToken } from '../../../services/AuthService';
import { fetchAccounts } from '../../../api/account';
import { fetchStrategies } from '../../../api/strategy'; 

const AddTradeForm = () => {
  const [formData, setFormData] = useState({
    symbol: '',
    symbolCode: '',
    side: '',
    entryTime: '',
    entryPrice: '',
    closingPrice: '',
    closingTime: '',
    volume: '',
    pips: '',
    pnl: '',
    netProfit: '',
    grossProfit: '',
    balance: '',
    commission: '',
    swap: '',
    accountID: '',
    strategyID: '',
    comment: '',
    result: '',
    img1: '',
    img2: '',
    img3: '',
  });
  const [accounts, setAccounts] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = getToken(); 
      const userID = getUserIdFromToken(token);
      setLoading(true);
      try {
        const accountsData = await fetchAccounts(userID);
        const strategiesData = await fetchStrategies(userID);
        setAccounts(accountsData);
        setStrategies(strategiesData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAccountChange = (e) => {
    const { value } = e.target;
    setSelectedAccount(value);
    setFormData({
      ...formData,
      accountID: value,
    });
  };

  const handleStrategyChange = (e) => {
    const { value } = e.target;
    setSelectedStrategy(value);
    setFormData({
      ...formData,
      strategyID: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken(); 
    const userID = getUserIdFromToken(token);
    const newTradeData = { ...formData, userID };

    try {
      const { data, error } = await createTrade(newTradeData);
      if (error) {
        console.error('Error creating trade:', error);
        setError('Error creating trade');
      } else {
        setSuccessMessage('Trade added successfully');
        setFormData({
          symbol: '',
          symbolCode: '',
          side: '',
          entryTime: '',
          entryPrice: '',
          closingPrice: '',
          closingTime: '',
          volume: '',
          pips: '',
          pnl: '',
          netProfit: '',
          grossProfit: '',
          balance: '',
          commission: '',
          swap: '',
          accountID: '',
          strategyID: '',
          comment: '',
          result: '',
          img1: '',
          img2: '',
          img3: '',
        });
        setSelectedAccount('');
        setSelectedStrategy('');
      }
    } catch (err) {
      console.error('Error creating trade:', err);
      setError('Error creating trade');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className=" container-fluid  bg-white">
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      
      {accounts.length > 0 && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Select Account</Form.Label>
            <Form.Control
              as="select"
              value={selectedAccount}
              onChange={handleAccountChange}
              required
            >
              <option value="">Select an account</option>
              {accounts.map(account => (
                <option key={account._id} value={account._id}>
                  {account.name} 
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {selectedAccount && (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="formStrategy">
                    <Form.Label>Select Strategy (Optional)</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedStrategy}
                      onChange={handleStrategyChange}
                    >
                      <option value="">Select a strategy (optional)</option>
                      {strategies.map(strategy => (
                        <option key={strategy._id} value={strategy._id}>
                          {strategy.name} 
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {Object.keys(formData).map((key) => (
                  key !== 'accountID' && key !== 'strategyID' && (
                    <Col md={6} className="mb-3" key={key}>
                      <Form.Group controlId={`form${key}`}>
                        <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
                        <Form.Control
                          type={key.includes('Time') ? 'datetime-local' : 'text'}
                          name={key}
                          value={formData[key]}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  )
                ))}
              </Row>

              <Button variant="primary" type="submit" className="mt-3">
                Add Trade
              </Button>
            </Form>
          )}
        </>
      )}
    </div>
  );
};

export default AddTradeForm;
